<template>
  <div v-if="promo" class="knives">
    <knives-intro />
    <div class="container">
      <div class="knives__header-buttons knives__header-buttons--mobile">
        <button
          class="knives__header-btn"
          @click="sctollToElement('conditions')"
        >
          {{ $t("knives.conditions") }}
        </button>

        <button class="knives__header-btn" @click="sctollToElement('partners')">
          {{ $t("knives.knivesPartners") }}
        </button>
        <button class="knives__header-btn" @click="sctollToElement('prizes')">
          {{ $t("knives.prizes") }}
        </button>
        <button class="knives__header-btn" @click="sctollToElement('rules')">
          {{ $t("knives.rules") }}
        </button>
      </div>
    </div>
    <knives-collection />
    <knives-products />
    <knives-youtube />
    <knives-partners />
    <knives-catalog />
    <knives-prizes />
    <knives-winners />
    <knives-rules />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { getPromo } from "@/api";
import { actionTypes } from "@/store/modules/knives";
export default {
  name: "PromotionKnives",
  components: {
    KnivesIntro: () => import("@/components/knives/KnivesIntro.vue"),
    KnivesCollection: () => import("@/components/knives/KnivesCollection.vue"),
    KnivesProducts: () => import("@/components/knives/KnivesProducts.vue"),
    KnivesYoutube: () => import("@/components/knives/KnivesYoutube.vue"),
    KnivesPartners: () => import("@/components/knives/KnivesPartners.vue"),
    KnivesCatalog: () => import("@/components/knives/KnivesCatalog.vue"),
    KnivesPrizes: () => import("@/components/knives/KnivesPrizes.vue"),
    KnivesWinners: () => import("@/components/knives/KnivesWinners.vue"),
    KnivesRules: () => import("@/components/knives/KnivesRules.vue"),
  },
  data() {
    return {
      promo: null,
    };
  },
  created() {
    getPromo(243).then((stock) => {
      this.promo = stock.data;
      this.getPromo(stock.data);
    });
  },
  methods: {
    ...mapActions("knivesModule", {
      getAnchor: actionTypes.getAnchor,
      getPromo: actionTypes.getPromo,
    }),
    sctollToElement(element) {
      switch (element) {
        case "conditions":
          document.getElementById("knives-conditions").scrollIntoView();
          break;
        case "partners":
          document.getElementById("knives-partners").scrollIntoView();
          break;
        case "prizes":
          document.getElementById("knives-prizes").scrollIntoView();
          break;
        case "rules":
          document.getElementById("knives-rules").scrollIntoView();
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
